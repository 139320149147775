.colorWrapper {
  background-color: #f9f9f9;
}

.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  padding: 2rem 0;
}

.featureContent {
  padding: 0 2rem;
}


.potentialContent {
  padding-right: 2rem;
}

.featureContent h2,
.potentialContent h2 {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  color: #111827;
}

.featureContent p,
.potentialContent p {
  color: #6B7280;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.featureList {
  list-style: none;
  margin: 2rem 0;
}

.featureList li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: #374151;
}

.featureList li::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #7C3AED;
  border-radius: 50%;
}

.dashboardGrid {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.dashboardGrid img {
  align-self: center;
  max-width: 280px;
  height: auto;
  border-radius: 8px;
  width: 100%;
}

.fullWidth {
  grid-column: span 2;
}

@media (max-width: 1024px) {
  .features, .worldPotential {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .featureContent, .potentialContent {
    padding-right: 0;
  }

  .dashboardGrid {
    margin-top: 2rem;
    flex-direction: row;
    gap: 1rem;
    padding: 0 2rem;
  }

  .dashboardGrid img {
    align-self: center;
    max-width: 180px;
  }
}