.colorWrapper {
  background-color: #fff;
}

.worldPotential {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2rem;
  padding: 2rem 0;
}

.potentialContent {
  padding: 0 2rem;
  width: 50%;
}

.potentialContent h2 {
  font-size: 2.5rem;
  font-weight: 800;
  color: #111827;
}

.potentialContent p {
  color: #474b53;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.mapImages {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 1rem;
  padding: 2rem;
  align-items: center;
}

.mapImage {
  max-width: 280px;
  height: auto;
  object-fit: cover;
  align-self: center;
  justify-self: center;
}

.mapImage.fullWidth {
  grid-column: 1 / span 2;
  grid-row: 2;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 280px;
  margin-top: 1rem;
}

@media (max-width: 1024px) {
  .worldPotential {
    flex-direction: column;
  }

  .potentialContent {
    width: auto;
    padding: 0 2rem;
  }

  .mapImages {
    width: auto;
    padding: 0 2rem;
  }

  .mapImage {
    max-width: 100px;
  }

  .mapImage.fullWidth {
    max-width: 100%;
  }
}