.colorWrapper {
  background-color: #fff;
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  min-height: 600px;
}

.content {
  padding: 0 2rem;
}

.content h1 {
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem;
}

.subtitle {
  color: #6B7280;
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  gap: 1rem;
}

.buttons button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.githubBtn {
  background-color: #111827;
  color: white;
  border: none;
}

.figmaBtn {
  background-color: white;
  color: #111827;
  border: 1px solid #E5E7EB;
}

.heroImage {
  position: relative;
  height: 100%;
}

.heroImage img {
  margin-left: -10%;
  width: 110%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}


@media (max-width: 1024px) {
  .main {
    grid-template-columns: 1fr;
  }
  .content {
    padding: 0;
  }

  .content h1 {
    font-size: 2.5rem;
    padding: 0 2rem;
  }

  .content p {
    padding: 0 2rem;
  }
  .heroImage img {
    display: none;
  }
}
