.colorWrapper {
  background-color: #f2f2f2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
}

.logo {
  width: 35%;
  max-width: 180px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 100;
  font-size: 1.5rem;
}

.logoIcon {
  width: 350px;
  height: auto;
}

.navLinksContainer {
  display: flex;
  gap: 2rem;
}

.navLinks {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.navLinks a {
  color: #4f1e00;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
}

.navLinks a:hover {
  color: #f79473;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.starCount {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  font-size: 0.875rem;
}

.count {
  background-color: #F3F4F6;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.langSwitch button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #7C3AED;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 0.5rem;
}

.menuButton {
  display: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0.5rem;
}

.selected {
  font-weight: bold;
  /* Add any other styling you want for the selected state */
  opacity: 1;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .menuButton {
    display: block;
  }

  .logo {
    width: 60%;
  }

  .logoIcon {
    width: 250px;
  }

  .navLinks {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: #f2f2f2;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 100;
  }

  .navLinks.showMobile {
    display: flex;
  }

  .navLinks a {
    padding: 0.5rem 0;
  }

  .nav {
    position: relative;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 70%;
  }

  .logo img {
    width: 230px;
  }

  .navLinksContainer {
    gap: 0.5rem;
  }

  .langSwitch {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }

  .langSwitch button {
    padding: 0.3rem 0.6rem;
    font-size: 1rem;
  }
}
