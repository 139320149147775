.colorWrapper {
  background-color: #271209;
}

.footer {
  padding: 4rem 0 2rem;
  color: #fff;
}

.footerContent {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
}

.footerLinks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
}

.linkColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.linkColumn h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.linkColumn a {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.1rem;
  font-size: 1.1rem;
  transition: color 0.2s;
}

.linkColumn a:hover {
  color: #f79473;
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}

.copyright {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #6B7280;
  font-size: 0.875rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logoIcon {
  width: 32px;
  height: 32px;
  background-color: #7C3AED;
  border-radius: 8px;
}

.copyright a {
  color: #7C3AED;
  text-decoration: none;
}

.socialLinks {
  display: flex;
  gap: 1.5rem;
}

.socialLinks a {
  color: #6B7280;
  font-size: 1.25rem;
  transition: color 0.2s;
}

.socialLinks a:hover {
  color: #111827;
}


.logoSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.logo img {
  max-width: 350px;
  height: auto;
}

.contactUs {
  margin-top: 1rem;
  color: #fff;
}

.contactUs h3 {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.contactUs a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.2s;
}

.contactUs a:hover {
  color: #f79473;
}


@media (max-width: 1024px) {
  .footerLinks {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .footerLinks {
    grid-template-columns: 1fr;
  }

  .footerBottom {
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  .copyright {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .footerLinks {
    grid-template-columns: 1fr;
  }

  .logo img {
    max-width: 250px;
  }
}