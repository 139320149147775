.app {
  margin: 0 auto;
  padding: 0;
}

.content {
  max-width: 1280px;
}




