.colorWrapper {
  background-color: #f2f2f2;
}

.newIdeas {
  padding: 4rem 0 2rem;
}

.trial {
  text-align: center;
  max-width: 600px;
  margin: 0 auto 4rem;
}

.trial h2 {
  font-size: 2.25rem;
  font-weight: 800;
  color: #111827;
  margin-bottom: 1rem;
}

.trial p {
  color: #6B7280;
  margin-bottom: 2rem;
}

.trialButton {
  background-color: #4621cc;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.trialButton:hover {
  background-color: #456ae0;
}